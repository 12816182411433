import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import './NavBar.css';
import {
  MobileIcon, Nav,
  NavBackdrop, NavbarContainer,
  NavItem,
  NavLink, NavLinks, NavMenu
} from './Navbar.elements';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>

        <Nav>
          {/* <div style={{ zIndex: 1, position: 'absolute', top: '0', left: 0 }} >

          </div> */}
          <NavBackdrop>

          </NavBackdrop>
          <NavbarContainer>
            <NavLink to='/'>
              <img src={require('../../images/logo.png')} height="70px" width="70px" alt='logo' />
              <div style={{padding:'17px 5px 5px'}}> 
                <div className="title-bar">LUTFI</div>
                <div>Architect</div>
              </div>

            </NavLink>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to='/' onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/services' onClick={closeMobileMenu}>
                  Services
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/contact-us' onClick={closeMobileMenu}>
                  Contact us
                </NavLinks>
              </NavItem>
              {/* <NavItem>
                <NavLinks to='/blog' onClick={closeMobileMenu}>
                  Blog
                </NavLinks>
              </NavItem> */}
              {/* <NavItemBtn>
                {button ? (
                  <NavBtnLink to='/sign-up'>
                    <Button primary>SIGN UP</Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to='/sign-up'>
                    <Button onClick={closeMobileMenu} fontBig primary>
                      SIGN UP
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn> */}
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
