import React from 'react';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <div className='cards__item__link' >
          <figure className='cards__item__pic-wrap' data-category="">
            <img
              className='cards__item__img'
              alt='Travel'
              src={props.src}
            />
          </figure>
          

          <div className='cards__item__info'>
            <div className="cards__item__title">{props.title}</div>  
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
          {/* <div>
                Build your dream home within your budget
          </div> */}
        </div>
      </li>
    </>
  );
}

export default CardItem;
