import React from 'react';
import './BlogItem.css';

function BlogItem(props) {
    return (
        <div className="card">
          <div className="card__img_wrap">
          <img src={props.img} className="card__img"  alt={props.title}/>
          </div>
          <div className="card__body">
            <h2 className="card__title">{props.title}</h2>
            <p className="card__description">{props.description}</p>
          </div>
        </div>
      );
}
export default BlogItem;