import React, { useEffect, useRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
import isNil from "lodash/fp/isNil";
import "./ModalStyles.css"
import BtnSlider from "../SliderCard/BtnSlider";

const Modal = ({ onCloseRequest, children, slideImages }) => {

    //const [projectSelected, setProjectSelected] = useState(slideImages);
    const [imageIndex, setImageIndex] = useState(0);
    const modal = useRef(null);

    const handleKeyUp = useCallback(
        e => {
            const keys = {
                27: () => {
                    e.preventDefault();
                    onCloseRequest();
                    window.removeEventListener("keyup", handleKeyUp, false);
                }
            };

            if (keys[e.keyCode]) {
                keys[e.keyCode]();
            }
        },
        [onCloseRequest]
    );

    const handleOutsideClick = useCallback(
        e => {
            console.log(">>>>>>>>>>");
            console.log(slideImages);
            if (!isNil(modal)) {
                if (!modal.current.contains(e.target)) {
                    onCloseRequest();
                    document.removeEventListener("click", handleOutsideClick, false);
                }
            }
        },
        [onCloseRequest]
    );

    useEffect(() => {
        window.addEventListener("keyup", handleKeyUp, false);
        document.addEventListener("click", handleOutsideClick, false);

        return () => {
            window.removeEventListener("keyup", handleKeyUp, false);
            document.removeEventListener("click", handleOutsideClick, false);
        };
    }, [handleKeyUp, handleOutsideClick]);

    function nextSlide() {
        console.log("projectSelected")
        //var index = slideImages.find(item => item === imageUrl);
        var length = slideImages.length;
        var index = (imageIndex < length - 1) ? imageIndex + 1 : 0;
        setImageIndex(index)
    }

    function prevSlide() {
        console.log("projectSelected");
        var length = slideImages.length;
        var index = (imageIndex === 0) ? length - 1 : imageIndex - 1;
        setImageIndex(index)
    }

    return (
        <div className="modalOverlay">
            <div className="modal" ref={modal}>
                {/* {children} */}
                <figure>
                    <img className="modal-img" src={slideImages[imageIndex]} alt="Site image" />
                    <BtnSlider moveSlide={nextSlide} direction={"next"} />
                    <BtnSlider moveSlide={prevSlide} direction={"prev"} />
                </figure>

            </div>
        </div>
    );
};

Modal.propTypes = {
    onCloseRequest: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default Modal;
