import React from 'react';

function InfoSection({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start
}) {
  return (
    <>
      <div className='cards'>
        <h1>{headline}</h1>
        <div style={{display:'flex', flexFlow:'column',alignItems: 'center'}}>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <div className="about_us_desc" >
                {description}
              </div>
            </ul>
          </div>
        </div>
      </div>
      {/* <InfoSec lightBg={lightBg}>
        <div style={{ padding: "0px 3em", color: 'black'}}> 
        <div><Heading lightText={lightText}>{headline}</Heading></div>
          {description}
        </div>

      </InfoSec> */}
      {/* <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                <Link to='/sign-up'>
                  <Button big fontBig primary={primary}>
                    {buttonLabel}
                  </Button>
                </Link>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <TextWrapper>
                About us
              </TextWrapper>
              <ImgWrapper start={start}>
                <Img src={img} alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec> */}
    </>
  );
}

export default InfoSection;
