import React from 'react';
import '../../App.css';
import { Button } from '../../components/Button/Button';

import './HeroSection.css';

function HeroSection({
  heroText,
  heroBg,
  buttonText,
  buttonAction
}) {
  return (
    <div className={'hero-container '+ heroBg}>
    
      <p>{heroText}</p>
     {(buttonText) ? <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          to={buttonAction}  
        >
          {buttonText}
        </Button>
       
      </div> : null }
    </div>
  );
}

export default HeroSection;
