import React, { useState } from 'react';
import './ContactForm.css'
import { Button } from '../../components/Button/Button';

const ContactForm = () => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [message, setMessage] = useState('')
    const [isSubmitInprogess, setIsSubmitInprogess] = useState(false)
    const [isIncompleteForm, setIsIncompleteForm] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)


    function resetForm() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setCity('');
        setMessage('');
        setIsSuccess(false);
        
    }

    // useEffect(() => {
    //     resetForm();
    // });

    const handleSubmit = async (e) => {
        setIsIncompleteForm(false) 
        e.preventDefault();
        // ???
        if(isEmpty(firstName) || isEmpty(lastName) || isEmpty(phone) || isEmpty(city) || isEmpty(message)){
            setIsIncompleteForm(true) 
            return  
        }
        var jsonBody = {
            "name": firstName + " " + lastName,
            "email": email,
            "phone": phone,
            "city": city,
            "message": message
        };


        //https://lutfi-architect-backend.herokuapp.com/send
        setIsSubmitInprogess(true);
        await fetch("https://lutfi-architect-backend.herokuapp.com/send", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(jsonBody),
        })
            .then((res) => res.json())
            .then(() => {
                resetForm();
                setIsSubmitInprogess(false);
                setIsIncompleteForm(false)
                setIsSuccess(true);
            });
    }

    function closeSuccessMessage() {
        setIsSuccess(false)
    }

    function isEmpty(val) {
        return val.trim().length === 0
    }


    return (
        <div>
            <form className="form-container">
                <input type="text" value={firstName} placeholder="First name" onChange={e => setFirstName(e.target.value)} className="form-input-large" />

                <input type="text" value={lastName} placeholder="Last name" onChange={e => setLastName(e.target.value)} className="form-input-large" />
                <input type="email" value={email} placeholder="Email" onChange={e => setEmail(e.target.value)} className="form-input-large" />
                <input type="tel" value={phone} placeholder="Phone" onChange={e => setPhone(e.target.value)} className="form-input-large" />

                <input type="text" value={city} placeholder="City" onChange={e => setCity(e.target.value)} className="form-input-large" />

                <textarea value={message} className="form-input-textarea" onChange={e => setMessage(e.target.value)} maxLength="250">

                </textarea>
                <div className="temp--padding">
                <Button buttonStyle="btn--thick" onClick={handleSubmit} >Get Started</Button>
                </div>
               
                {isSuccess ? <div style={{ background: "#71a871", color: "#EEEE", padding: "0 5px" }}>
                    Your enquiry is submtted succesfully
                    <button onClick={closeSuccessMessage} style={{ border: "0", background: "transparent", padding: "0 6px", color: "white", float: "right" }} type="button" className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> : <></>}

                {isSubmitInprogess ? <div style={{ background: "#c0b12b", color: "#EEEE", padding: "0 5px" }}>
                    Submittng your request. Please wait
                </div> : <></>}

                {isIncompleteForm ? <div style={{ background: "#f1430e", color: "#EEEE", padding: "0 5px" }}>
                   Please fill all the fields in the form
                </div> : <></>}
            </form>
        </div>
    );
};

export default ContactForm;