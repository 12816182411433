import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import ContactForm from '../contactus/ContactForm';
import './services.css';
import UpComingProject from './UpComingProject';
import WhyChooseUs from './WhyChooseUs';



const Services = () => {


  return (
    <div>
      <HeroSection
        heroText="Build a perfect home within your budget"
        heroBg="servicepage-bg"
        buttonAction="/contact-us"
        buttonText="Request Quote"
      />
      <div style={{ textAlign: 'center', padding: '4em 2em' }}>
        <h2 className='section-header'>Welcome to Lutfi Architecture</h2>
        <div className='section-content'>
        <p style={{textAlign: "justify"}}>Lutfi Architecture is one of the pioneers in the construction sector. We have been in this sector for more than a year. By the enormous amount of experience, we have been satisfying our customers more than their expectations. </p> <br/>
        <p style={{textAlign: "justify"}}>Lutfi architecture has well experienced and knowledgeable workers, who have sound knowledge of what they are up to. We have experience in structural design, 2d drawing, 3d modelling, residential design, commercial design and architectural design. </p>
      </div>

      </div>
      <WhyChooseUs/>
      <UpComingProject/>
      <div className="service-form">
          <h1>For customize solution kindly fill in your details</h1>
          <ContactForm/>
      </div>
      
    </div>
  );
};

export default Services;
