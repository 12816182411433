import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/home/home';
import About from './pages/about';
import Services from './pages/services/services';
import Contact from './pages/contactus/contact';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Blogs from './pages/blogs/blog';
import ScrollToTop from './scrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop>
      <Navbar/>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' component={About} />
        <Route path='/services' component={Services} />
        <Route path='/contact-us' component={Contact} />
        <Route path='/blog' component={Blogs} />
      </Switch>
      <Footer/>
      </ScrollToTop>
      
    </Router>
  );
}

export default App;
