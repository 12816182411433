import React from 'react';
import CardItem from '../../components/card/CardItem';
import '../home/ServiceSection.css'

function WhyChooseUs(props) {
    return (
        <div className='cards'>
        <h1>Why Choose us to build your home?</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardItem
                src='images/project-completion.webp'
                title='Project completion within budget'
                text='We will deliver your home at a price which we promised you. There will be no cost overruns. No extra amount of money will be included in your bill without your approval'
                label='Adventure'
                
              />
               <CardItem
                src='images/discount.webp'
                title='Get products lower than MRP'
                text='Since we are in the construction industry for over a decade, we have build a very good relationship with our suppliers. We will ensure that you will get all the materials required for making your house at a lesser price'
                label='Luxury'
                
              />
               <CardItem
                src='images/product-sample.jpeg'
                title='View sample products'
                text='All materials such as tiles, electrical products, kitchen items, bathroom stuff etc will be shown to you for inspection before approval.'
                label='Luxury'
                
              />     
            </ul>
            <ul  className='cards__items'>
            <CardItem
                src='images/building-maintenance3.png'
                title='Maintenance service'
                text='You will have option to choose annual home maintenance service. This service will help to expand the life of your property.'
                label='Luxury'
              
              />
               <CardItem
                src='images/onsite-photo.jpeg'
                title='On site photos for Inspection'
                text='Pictures of the construction site will be shared on a day to day basis so that you can track the progress of our work.'
                label='Adventure'
                
              />
            </ul>
            
          </div>
        </div>
      </div>
    );
}

export default WhyChooseUs; 