import React from 'react';
import './contact.css'
import ContactForm from './ContactForm';

function Contact() {
  
  return <div className="main-container">
    <div className="contact-container">
      <div className="contact-form-container">
        <h1>Contact Us</h1>
        <p style={{padding: "10px 0"}}>For any Inquiries kindly fill your details here</p>

        <ContactForm />
      </div>

    </div>
    <div className="contact-container">
      <div className="contact-address" >
        <h3 style={{paddingBottom:20}}>Address </h3>
        <p style={{paddingBottom:10, fontSize:25}}><b>S.Sanjay B.Arch.,</b></p>
        <p>No:3, Ambuja Complex, Raja Rajesewari Nagar,</p>
        <p>Villupuram, Tamilnadu 605602</p>
        <p><b>Email:</b> lutfisanjay04@gmail.com</p>
        <p><b>Tel:</b> 9944775966</p>
      </div>

  
    </div>
  </div>
};

export default Contact;
