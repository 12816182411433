import React, { useState } from 'react';
import BlogItem from './BlogItem';
import './BlogItem.css';

function Blogs() {
  const filter_cat = [
    {
      filter: 'all',
      name: 'All Post'
    },
    {
      filter: 'property_maintenence',
      name: 'Property mainatenence'
    },
    {
      filter: 'cost_detail',
      name: 'Cost details'
    }
  ]

  const [currentSelectedFiler, setCurrentSelectedFiler] = useState(filter_cat[0].filter);

  const blogItems = [
    {
      "id": "1",
      "img": "https://static.wixstatic.com/media/fd9f9c_f85ff92608114db49b088f9c83d32283~mv2.jpg/v1/fill/w_836,h_628,fp_0.50_0.50,q_90/fd9f9c_f85ff92608114db49b088f9c83d32283~mv2.webp",
      "title": "11 Popular swimming pool designs & cost of building them in India",
      "description": "A place to hang out with friends, a place to enjoy summer vacations for kids, or a tranquil place to be lost in ones’ thoughts",
      "view": "60,956",
      "filter_cat": ["all", "property_maintenence"]
    },
    {
      "id": "2",
      "img": "https://static.wixstatic.com/media/fd9f9c_7b5c3e3c63b14ca384861b2d175755a0~mv2.jpg/v1/fill/w_836,h_628,fp_0.50_0.50,q_90/fd9f9c_7b5c3e3c63b14ca384861b2d175755a0~mv2.webp",
      "title": "Interior Design Costs For Indian Homes",
      "description": "Know all the important factors you need to know when considering to design your house",
      "view": "130",
      "filter_cat": ["all", "cost_detail"]
    },
    {
      "id": "3",
      "img": "https://static.wixstatic.com/media/fd9f9c_c124df7688144f6690ef4afb6e847e3f~mv2.jpg/v1/fill/w_908,h_682,fp_0.50_0.50,q_90/fd9f9c_c124df7688144f6690ef4afb6e847e3f~mv2.webp",
      "title": "How much does it cost to build a home in India?",
      "description": "Understand how the cost of building your dream house differs based on your requirement.",
      "view": "780",
      "filter_cat": ["all", "cost_detail"]
    },
    {
      "id": "4",
      "img": "https://static.wixstatic.com/media/fd9f9c_3a5087c307ad41b296126fe20e84536f~mv2.jpg/v1/fill/w_836,h_628,fp_0.50_0.50,q_90/fd9f9c_3a5087c307ad41b296126fe20e84536f~mv2.webp",
      "title": "3 types of swimming pool & cost of building them in India",
      "description": "Know all the details regarding building gunite pool, fiber glass pool & vinyl pool in India",
      "view": "43,190",
      "filter_cat": ["all", "cost_detail"]
    },
    {
      "id": "5",
      "img": "https://static.wixstatic.com/media/fd9f9c_21f6d656aa4d4961bffe5b362a124400~mv2.jpg/v1/fill/w_836,h_628,fp_0.50_0.50,q_90/fd9f9c_21f6d656aa4d4961bffe5b362a124400~mv2.webp",
      "title": "7 things to consider before choosing a property maintenance company",
      "description": "Know how to choose a property management company who can ensure that the value of your property remains high through out the time",
      "view": "76,247",
      "filter_cat": ["all", "property_maintenence"]
    }

  ]

  const filter = (type) => {
    console.log("Filter.." + type);
    setCurrentSelectedFiler(type);
  }

  const filter_buttons = filter_cat.map(item => (
    <button key={item.filter} className={(currentSelectedFiler === item.filter) ? 'filter_chip chip_selected' : 'filter_chip'} onClick={() => filter(item.filter)}>{item.name}</button>
  ));

  return (
    <div>
      <div className="filters">
        {filter_buttons}
      </div>
      <div className="block-page">

        <div className="wrapper">
          {blogItems.filter(blog => blog.filter_cat.includes(currentSelectedFiler)).map(blog =>
            <BlogItem
              key={blog.id}
              img={blog.img}
              title={blog.title}
              description={blog.description}
              views={blog.view}

            />
          )}

        </div>

      </div>
    </div>
  );
};

export default Blogs;
