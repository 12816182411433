import React, { useState } from 'react';
import Modal from '../../components/dialog/Modal';
import SliderCard from '../../components/SliderCard/SliderCard';
import '../home/ServiceSection.css';


function UpComingProject() {

    const [showModal, setShowModal] = useState(false);
    

    const upcomingProjects = [
        {
            id: "1",
            title: "Mr Manikandan",
            location: "Location - Villupuruam",
            projectValue: "1600 sqft BUA",
            slides: getImages("images/first_project/pic", 28)
        },
        {
            id: "2",
            title: "Inder",
            location: "Location - Villupuruam",
            projectValue: "5150 sqft BUA",
            slides: getImages("images/second_project/pic", 24)
        },
        {
            id: "3",
            title: "RMV Kumaran",
            location: "Location - Villupuruam",
            projectValue: "3500 sqft BUA",
            slides: getImages("images/third_project/pic", 28)
        }
    ];

    const [projectSelected, setProjectSelected] = useState(upcomingProjects[0]);


    function getImages(imagePath, count) {
        var imageArr = []
        for (var i = 1; i <= count; i++) {
            imageArr.push(imagePath + i + ".jpeg")
        }
        return imageArr;
    }

    function onSlideImageClick(project) {
       // NiceModal.register('my-antd-modal', MyAntdModal)
       // NiceModal.show('my-antd-modal', { name: 'Nate' })
        setProjectSelected(project.slides);
        setShowModal(true)
    }

    return (
        <>
            <h1>Our projects</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        {upcomingProjects.map((prj) => {
                            return <SliderCard key={prj.id} slide={prj.slides} title={prj.title} location={prj.location} projectValue={prj.projectValue} onClick={() => onSlideImageClick(prj)} />
                        })}
                    </ul>
                </div>
            </div>

            {showModal && (
                <Modal onCloseRequest={() => setShowModal(false)} slideImages={projectSelected} >

                   

                </Modal>
            )}
        </>
    );
}

export default UpComingProject;