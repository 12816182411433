import React from 'react';
import {
  FaFacebook,
  FaInstagram} from 'react-icons/fa';
import {
  FooterContainer,
  SocialIconLink, SocialIcons, SocialMedia,
  SocialMediaWrap,
  WebsiteRights
} from './Footer.elements';

function Footer() {
  return (
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
          {/* <SocialLogo to='/'>
            <SocialIcon />
            <img src={require('../../images/logo.jpg')} height="80px" width="80px" alt='logo' />
          </SocialLogo> */}
          <WebsiteRights>© 2021 by Lutfi Contruction Pvt Ltd </WebsiteRights>
          <SocialIcons>
            <SocialIconLink href='https://www.facebook.com/sanjay.srinivasan.756' target='_blank' aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href='https://www.instagram.com/lutfi_architects/' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
           
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
