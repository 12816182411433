import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import InfoSection from '../../components/InfoSection/InfoSection';
// import HeroSection from './HeroSection';
import ServiceSection from './ServiceSection';


const aboutContent = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,

  headline:
    'About Us',
  description:
    "In the year 2019, we started this company Lutfi architect successfully improve ourselves, our best to the people. Honesty, trustworthy and on time are the guiding value of our business. It is a company with a reputation of excellence that has stood the test of time. Every project bears the hallmark of quality and hundreds of happy families stand testimony to our commitment to creating the right environment that enhances the quality of the life and provide peace, tranquillity and comfort.",
  buttonLabel: 'View Case Study',
  imgStart: 'start',
  img: require('../../images/team.webp'),
  alt: 'Vault',
  start: 'true'
};


const Home = () => {
  return (
    <div>
      <HeroSection 
        heroText="Get your project on time within your budget" 
        heroBg="mainpage-bg"
        buttonText="REACH US"
        buttonAction = "/contact-us"
        />
      <InfoSection {...aboutContent} />
      <ServiceSection />
      {/* <ClientSection /> */}
    </div>
  );
};

export default Home;
