import React from 'react';
import CardItem from '../../components/card/CardItem';
import ClicableCardItem from '../../components/card/ClicableCardItem';
import { InfoColumn, InfoRow, InfoSec } from '../../components/InfoSection/InfoSection.elements';
import { Container } from '../../globalStyles';
import './ServiceSection.css';



function ServiceSection() {
  return (
    <>
      <InfoSec lightBg={true}>
        <Container>
          <InfoRow imgStart={false}>
            <InfoColumn>
              <div className="service-title">
                Our Service
              </div>  
            </InfoColumn>
            <InfoColumn>
              <ClicableCardItem
                src='images/building_construction.webp'
                title='Residence, Renovation, commercial'
                text='Build your dream home within your budget'
                label='Adventure'
                path='/services'
              />
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
}

export default ServiceSection;
