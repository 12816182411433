import React, { useState } from 'react';
import BtnSlider from './BtnSlider';
import './SliderCard.css';

function SliderCard(props) {

    const [slideIndex, setSlideIndex] = useState(1)


    const nextSlide = () => {
        if (slideIndex !== props.slide.length) {
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === props.slide.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1) {
            setSlideIndex(props.slide.length)
        }
    }

    const openGallery = () => {
        // console.log("asdasd")
        // NiceModal.register('my-antd-modal', MyAntdModal)
        // NiceModal.show('my-antd-modal', { name: 'Nate' })
        // console.log("done")
        props.onClick();
    }


    return (
        <div className="slider-car-item">
            <div className="container-slider">
                {props.slide.map((obj, index) => {
                    return (
                        <div key={index + ''} className={slideIndex === index + 1 ? "slide active-anim" : "slide"}>
                            <img src={obj} alt="Slide" onClick={() => openGallery()} />
                        </div>
                    )
                })}
                <BtnSlider moveSlide={nextSlide} direction={"next"} />
                <BtnSlider moveSlide={prevSlide} direction={"prev"} />
                
                
           
            </div>
            <div className="slider-info">
                <div className="slider-info-title">{props.title} </div>
                <div className="slider-info-content">{props.location}</div>
                <div className="slider-info-content">{props.projectValue}</div>
            </div>
            
            
        </div>

    );
}

export default SliderCard;